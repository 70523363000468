import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Flex,
  InputNumber,
  Row,
  Typography,
} from "antd";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../store/homeApplication/homeApplicationSlice";
import { current } from "@reduxjs/toolkit";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const CoverageSelect = (props) => {
  const { title, options, name, hasCustom, hidePremium } = props;
  const { watch, setValue } = useFormContext();
  const dispatch = useDispatch();
  const step = useSelector((state) => state.homeApplication.step);
  const progress = useSelector((state) => state.homeApplication.progress);

  var currentProgress = progress[step - 1];

  const value = watch(name);
  const customValue = hasCustom && watch(name + "_custom");

  const resetValue = () => {
    setValue(name, null, {
      shouldValidate: true,
      shouldDirty: true,
    });

    // if (progressReset || progressReset === 0) {
    //   dispatch(setProgress({ step: step, progress: progressReset }));
    // }
  };

  return (
    <div style={{ paddingBottom: "12px" }} id={name}>
      <Collapse
        onChange={() => {}}
        activeKey={name}
        size="large"
        items={[
          {
            key: name,
            label: (
              <Typography.Title
                level={5}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                {title}
              </Typography.Title>
            ),
            children: (
              <div>
                <Typography.Text strong>Select limit:</Typography.Text>
                <Row gutter={[8, 8]}>
                  {options.map((v, i) => {
                    var newwidth = v == "Exclude protection" ? 175 : 125;
                    return (
                      <Col>
                        <Button
                          size="large"
                          style={{
                            whiteSpace: "normal",
                            height: "auto",
                            minHeight: "40px",
                            width: newwidth,
                          }}
                          type={
                            !customValue && value == i + 1
                              ? "primary"
                              : "default"
                          }
                          onClick={() => {
                            if (!value && !customValue) {
                              dispatch(
                                setProgress({
                                  step: step,
                                  progress: currentProgress + 17,
                                })
                              );
                            }
                            if (customValue) {
                              setValue(name + "_custom", null, {
                                shouldValidate: true,
                                shouldDirty: true,
                              });
                            }
                            setValue(name, i + 1, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }}
                        >
                          {v}
                        </Button>
                      </Col>
                    );
                  })}
                  {hasCustom && (
                    <Col>
                      <InputNumber
                        className={customValue ? "coverage-select-input" : null}
                        style={{ width: 258 }}
                        addonBefore={<div style={{}}>$</div>}
                        placeholder="Custom"
                        size="large"
                        changeOnBlur={true}
                        onChange={(v) => {
                          if (v) {
                            setValue(name + "_custom", v, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            if (!value && !customValue) {
                              dispatch(
                                setProgress({
                                  step: step,
                                  progress: currentProgress + 17,
                                })
                              );
                            }
                          } else {
                            if (!value) {
                              dispatch(
                                setProgress({
                                  step: step,
                                  progress: currentProgress - 17,
                                })
                              );
                            }
                            setValue(name + "_custom", null, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }
                        }}
                        value={customValue}
                      />
                    </Col>
                  )}
                </Row>
                {!hidePremium && (
                  <Divider dashed style={{ marginTop: 16, marginBottom: 8 }} />
                )}
                {!hidePremium && (
                  <Typography.Text strong>
                    Premium:{" "}
                    {currencyFormatter.format(
                      getPremium(name, value, customValue)
                    )}
                    /month
                  </Typography.Text>
                )}
              </div>
            ),
            showArrow: false,
          },
        ]}
      />
    </div>
  );
};

export const getPremium = (name, first, second) => {
  if (name == "building") {
    if (second) {
      return parseFloat(second ?? 0) / 20000;
    }
    if (first === 1) return 50;
    if (first === 2) return 100;
  } else if (name == "personal_properties") {
    if (second) {
      return parseFloat(second ?? 0) / 860;
    }
    if (first === 1) return 29.07;
  } else if (name == "additional") {
    if (second) {
      return parseFloat(second ?? 0) / 1000;
    }
    if (first === 2) return 55;
  } else if (name == "premises") {
    if (second) {
      return parseFloat(second ?? 0) / 95000;
    }
    if (first === 1) return 500000 / 95000;
    if (first === 2) return 1000000 / 95000;
    if (first === 3) return 2000000 / 95000;
  }
  return 0;
};
