import { createSlice } from "@reduxjs/toolkit";
import { fetchApplication } from "./homeApplicationThunks";

const initialState = {
  fetchStatus: null,
  isSaving: false,
  step: 1,
  data: null,
  progress: [5, 0, 0, 0],
};

export const homeApplication = createSlice({
  name: "homeApplication",
  initialState,
  reducers: {
    clearHomeApplication: {
      reducer(state) {
        state.fetchStatus = null;
        state.isSaving = false;
        state.data = null;
        state.step = 1;
        state.progress = [5, 0, 0, 0];
      },
    },
    navigateToPreviousPage: {
      reducer(state, action) {
        const { step, data } = action.payload;
        state.data = data;
        if (step) {
          state.step = Math.max(1, step);
        } else {
          state.step = state.step - 1;
        }
      },
      prepare({ step, data }) {
        return {
          payload: { step, data },
        };
      },
    },
    navigateToNextPage: {
      reducer(state, action) {
        state.data = action.payload;
        state.step = state.step + 1;
      },
    },
    setProgress: {
      reducer(state, action) {
        const { step, progress } = action.payload;
        const copy = state.progress;
        copy[step - 1] = progress;
        state.progress = copy;
      },
      prepare({ step, progress }) {
        return {
          payload: { step, progress },
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplication.pending, (state) => {
        state.fetchStatus = "pending";
        state.isSaving = false;
        state.data = null;
        state.step = 1;
        state.progress = [5, 0, 0, 0];
      })
      .addCase(fetchApplication.fulfilled, (state, { payload }) => {
        state.fetchStatus = "fulfilled";
        state.data = { ...payload.data, home: 1 };
      })
      .addCase(fetchApplication.rejected, (state) => {
        state.fetchStatus = "rejected";
        state.isSaving = false;
        state.data = null;
        state.step = 1;
        state.progress = [5, 0, 0, 0];
      });
  },
});

export const {
  clearHomeApplication,
  navigateToPreviousPage,
  navigateToNextPage,
  setProgress,
} = homeApplication.actions;

export default homeApplication.reducer;
