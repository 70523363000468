import { Layout, Typography } from "antd";

const LayoutFooter = () => {
  return (
    <Layout.Footer style={{ paddingLeft: "12px", paddingRight: "12px" }}>
      <Typography.Title
        level={5}
        style={{ marginTop: "8px", marginBottom: "8px" }}
      >
        homelink ©2024
      </Typography.Title>
      <Typography.Text style={{ marginTop: "8px", marginBottom: "8px" }}>
        Need help? Call us at +1-888-123-4567
        <br />
      </Typography.Text>
      <Typography.Text
        style={{ marginTop: "8px", marginBottom: "8px", fontSize: "11px" }}
        italic
      >
        Insurance is sold by (Seller). Home Insurance is underwritten by
        (Underwriter). Legal protection insurance is underwritten by
        (Underwriter). Other information here [...]
      </Typography.Text>
    </Layout.Footer>
  );
};

export default LayoutFooter;
