import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Col, Flex, Modal, Row, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../store/homeApplication/homeApplicationSlice";

export const MultiOptionsSelect = (props) => {
  if (props.visible) {
    return <MultiOptionsSelectBody {...props} />;
  }
  return null;
};

const MultiOptionsSelectBody = (props) => {
  const {
    title,
    options,
    width,
    name,
    isDisabled,
    forceScroll,
    previousController,
    progressIncrease,
    progressReset,
  } = props;
  const { watch, setValue } = useFormContext();
  const dispatch = useDispatch();
  const step = useSelector((state) => state.homeApplication.step);
  const progress = useSelector((state) => state.homeApplication.progress);
  const [modal, contextHolder] = Modal.useModal();
  var currentProgress = progress[step - 1];
  function scrollToTargetAdjusted(element) {
    var headerOffset = 58;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  const compRef = useRef();
  const value = watch(name);
  const resetValue = () => {
    setValue(name, null, {
      shouldValidate: true,
      shouldDirty: true,
    });

    compRef?.current && scrollToTargetAdjusted(compRef.current);
    if (progressReset || progressReset === 0) {
      dispatch(setProgress({ step: step, progress: progressReset }));
    }
  };

  useEffect(() => {
    if ((!value || forceScroll) && previousController) {
      var element = document.getElementById(previousController);
      element && scrollToTargetAdjusted(element);
    }

    if (currentProgress < progressIncrease && value) {
      dispatch(setProgress({ step: step, progress: progressIncrease }));
    } else if (currentProgress < progressReset) {
      dispatch(setProgress({ step: step, progress: progressIncrease }));
    }
  }, []);

  return (
    <div ref={compRef} style={{ paddingBottom: "12px" }} id={name}>
      <Row>
        <Col span={24}>
          <Typography.Title
            level={4}
            style={{ marginTop: "12px", marginBottom: "12px" }}
          >
            {title}
          </Typography.Title>
        </Col>
      </Row>
      {value && (
        <Flex justify="flex-start">
          <Button
            size="large"
            style={{
              whiteSpace: "normal",
              height: "auto",
              minHeight: "40px",
              width: width,
            }}
            type="primary"
            disabled={isDisabled}
            onClick={resetValue}
          >
            <Flex justify="space-between" style={{ width: "100%" }}>
              <span style={{ textAlign: "left" }}>{options[value - 1]}</span>
              {!isDisabled && (
                <Flex vertical align="center" justify="space-around">
                  <CheckCircleTwoTone
                    style={{ marginLeft: "14px", fontSize: "16px" }}
                    twoToneColor="#AB2217"
                  />
                </Flex>
              )}
            </Flex>
          </Button>
          {!isDisabled && (
            <Flex vertical align="center" justify="space-around">
              <Button type="link" onClick={resetValue}>
                <div style={{ textDecoration: "underline" }}>Edit</div>
              </Button>
            </Flex>
          )}
        </Flex>
      )}
      {!value && (
        <Row gutter={[12, 12]}>
          {options.map((v, i) => (
            <Col span={24}>
              <Button
                size="large"
                style={{
                  whiteSpace: "normal",
                  height: "auto",
                  minHeight: "40px",
                  width: width,
                }}
                onClick={() => {
                  setValue(name, i + 1, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  if (progressIncrease) {
                    dispatch(
                      setProgress({ step: step, progress: progressIncrease })
                    );
                  }
                  if (name == "credit_check" && i == 1) {
                    modal.warning({
                      title: "Unable to quote",
                      content:
                        "Unfortunately, we are unable to provide quotes to customers who do not authorize the collection and use of their claim histories, or the completion of a soft credit check.",
                      okText: "I understand",
                      okButtonProps: { size: "large" },
                    });
                  }
                }}
              >
                <Flex justify="space-between" style={{ width: "100%" }}>
                  <span style={{ textAlign: "left" }}>{v}</span>
                  <Flex vertical align="center" justify="space-around">
                    <span className="radio-button__control"></span>
                  </Flex>
                </Flex>
              </Button>
            </Col>
          ))}
        </Row>
      )}
      {name == "credit_check" && contextHolder}
    </div>
  );
};
