import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchApplication = createAsyncThunk(
  "homeApplication/fetch",
  async ({ id }, api) => {
    const baseUrl = process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.get(baseUrl + "/home_applications/" + id);
      return response;
    } catch (error) {
      return api.rejectWithValue(null);
    }
  }
);

export const submitApplication = createAsyncThunk(
  "homeApplication/submit",
  async ({ id }, api) => {
    const baseUrl = process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.patchForm(
        baseUrl + "/home_applications/" + id + "/complete"
      );
      return response;
    } catch (error) {
      return api.rejectWithValue(null);
    }
  }
);
