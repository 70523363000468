export const mainTheme = {
  token: {
    // borderRadius: 1,
    colorPrimary: "#A90011",
    colorSuccess: "#8f9779",
    colorLink: "black",
    colorLinkActive: "black",
    colorLinkHover: "black",
  },
  components: {
    Progress: {
      defaultColor: "#660001",
      /* here is your component tokens */
    },
    Menu: {
      darkItemBg: "#210304",
    },
  },
};

export const outerLayersTheme = {
  token: {
    // borderRadius: 1,
    // colorPrimary: "#ab2217",
  },
  components: {
    Button: {
      // colorPrimaryHover: "#910000",
      // colorPrimaryActive: "#ab2217",
    },
  },
};
