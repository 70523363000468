import { Col, Layout, Row, Typography } from "antd";

const MainHeader = (props) => {
  return (
    <Layout.Header className="main-header">
      <Row>
        <Col flex="200px" style={{ marginRight: "0px" }}>
          <Typography.Title
            level={3}
            style={{
              marginTop: 16,
              marginBottom: 0,
              marginLeft: 12,
              color: "white",
            }}
          >
            homelink
          </Typography.Title>
        </Col>
        <Col flex="auto">{props.children}</Col>
      </Row>
    </Layout.Header>
  );
};

export default MainHeader;
