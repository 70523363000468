import { useFormContext } from "react-hook-form";
import { MultiOptionsSelect } from "../../../shared/MultiOptionsSelect";

const DescribeHome = () => {
  const { watch } = useFormContext();

  const values = watch();

  const yearPurchased = 2025 - (values?.year_purchased ?? 1);
  const isQ2Visible =
    values?.year_built && yearPurchased - values?.year_built + 1 < 2009;
  const isQ3Visible =
    (isQ2Visible && values?.year_roof_updated) ||
    (values?.year_built && yearPurchased - values?.year_built + 1 > 2008);
  const isQ4Visible = isQ3Visible && values?.basement === 1;
  const isQ5Visible =
    (isQ4Visible && values?.basement_finished) ||
    (isQ3Visible && values?.basement === 2);
  const isQ6Visible = isQ5Visible && values?.num_stories;
  const isQ7Visible = isQ6Visible && values?.num_bathrooms;
  const isWaterHeaterQuestionVisible = isQ7Visible && values?.num_fireplaces;
  const isQ8Visible =
    isWaterHeaterQuestionVisible && values?.water_heater_replaced;
  const isQ9Visible = isQ8Visible && values?.construction_type;
  const isQ10Visible = isQ9Visible && values?.roof_type;

  return (
    <div>
      <MultiOptionsSelect
        visible={true}
        forceScroll={true}
        previousController="year_built"
        name="year_built"
        title="In what year was the home built?"
        options={Array.from({ length: 25 }, (_, i) =>
          (yearPurchased - i).toString()
        )}
        width={115}
        progressReset={0}
        progressIncrease={10}
      />

      <MultiOptionsSelect
        previousController="year_built"
        visible={isQ2Visible}
        name="year_roof_updated"
        title="What year was the home's roof last replaced?"
        options={[
          "2021 to 2024",
          "2016 to 2020",
          "2011 to 2015",
          "2006 to 2010",
          "Never",
          "Don't know",
        ]}
        width={175}
        progressReset={10}
        progressIncrease={20}
      />

      <MultiOptionsSelect
        name="basement"
        previousController={isQ2Visible ? "year_roof_updated" : "year_built"}
        visible={isQ3Visible}
        title="Does the home have a full or partial basement?"
        options={["Yes", "No"]}
        width={100}
        progressReset={20}
        progressIncrease={30}
      />

      <MultiOptionsSelect
        name="basement_finished"
        previousController={"basement"}
        visible={isQ4Visible}
        title="How much of the basement is finished?"
        options={[
          "All of it",
          "More than half of it",
          "Less than half of it",
          "None of it",
        ]}
        width={200}
        progressReset={30}
        progressIncrease={40}
      />
      <MultiOptionsSelect
        name="num_stories"
        previousController={isQ4Visible ? "basement_finished" : "basement"}
        visible={isQ5Visible}
        title="How many stories is the home?"
        options={["1", "2", "3"]}
        width={75}
        progressReset={40}
        progressIncrease={50}
      />
      <MultiOptionsSelect
        name="num_bathrooms"
        previousController={"num_stories"}
        visible={isQ6Visible}
        title="How many bathrooms does the home have?"
        options={["1", "2", "3", "4 or more"]}
        width={150}
        progressReset={50}
        progressIncrease={60}
      />
      <MultiOptionsSelect
        name="num_fireplaces"
        previousController={"num_bathrooms"}
        visible={isQ7Visible}
        title="How many fireplaces does the home have?"
        options={["None", "1", "2", "3", "4 or more"]}
        width={150}
        progressReset={60}
        progressIncrease={65}
      />
      <MultiOptionsSelect
        name="water_heater_replaced"
        previousController={"num_fireplaces"}
        visible={isWaterHeaterQuestionVisible}
        title="If your home has a tank-style water heater, when was it last replaced?"
        options={[
          "I have a tankless water heater",
          "2021 to 2024",
          "2011 to 2020",
          "2001 to 2010",
          "2000 or before",
          "Don't know",
        ]}
        width={200}
        progressReset={65}
        progressIncrease={70}
      />
      <MultiOptionsSelect
        name="construction_type"
        previousController={"water_heater_replaced"}
        visible={isQ8Visible}
        title="What construction type is the home?"
        options={[
          "Wood frame",
          "Concrete",
          "Masonry",
          "Steel",
          "Log",
          "Don't know",
        ]}
        width={160}
        progressReset={70}
        progressIncrease={80}
      />
      <MultiOptionsSelect
        name="roof_type"
        previousController={"construction_type"}
        visible={isQ9Visible}
        title="What type of roof is on the home?"
        options={[
          "Asphalt",
          "Composite",
          "Green",
          "Metal",
          "Rubber",
          "Tar and gravel",
          "Tile",
          "Wood",
          "Don't know",
        ]}
        width={165}
        progressReset={80}
        progressIncrease={90}
      />
      <MultiOptionsSelect
        name="water_damage"
        previousController={"roof_type"}
        visible={isQ10Visible}
        title="Since you purchased the home, how many times has it experienced water-related damage?"
        options={["None", "1 time", "2 times", "3 or more times"]}
        width={200}
        progressReset={90}
        progressIncrease={100}
        forceScroll={true}
      />
    </div>
  );
};

export default DescribeHome;
