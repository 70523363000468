import { useFormContext } from "react-hook-form";
import { MultiOptionsSelect } from "../../../shared/MultiOptionsSelect";

const InitialInfo = () => {
  const { watch } = useFormContext();

  const values = watch();

  const isQ1Visible = values?.home;
  const isQ2Visible = isQ1Visible && values?.own_or_rent;
  const isQ3Visible = isQ2Visible && values?.dwelling_type;
  const isQ4Visible = isQ3Visible && values?.year_purchased;
  const isQ5Visible = isQ4Visible && values?.primary_residence;
  const isQ6Visible = isQ5Visible && values?.home_sharing;

  return (
    <div>
      <MultiOptionsSelect
        forceScroll={true}
        previousController="home"
        visible={true}
        name="home"
        title="What is the home's address?"
        options={[values.address, "This is not my home address"]}
        width={300}
        progressReset={0}
        progressIncrease={5}
      />

      <MultiOptionsSelect
        previousController="home"
        visible={isQ1Visible}
        name="own_or_rent"
        title="Do you own the home or rent it?"
        options={["I am the owner of the home", "I am a tenant in the home"]}
        width={250}
        progressReset={5}
        progressIncrease={15}
      />
      <MultiOptionsSelect
        name="dwelling_type"
        previousController="own_or_rent"
        visible={isQ2Visible}
        title="What type of home is it?"
        options={[
          "Apartment or condo",
          "Detached house",
          "Mobile home",
          "Semi-detached (Duplex)",
          "Town house (Row house)",
        ]}
        width={275}
        progressReset={15}
        progressIncrease={25}
      />
      <MultiOptionsSelect
        name="year_purchased"
        previousController="dwelling_type"
        visible={isQ3Visible}
        title="What year did you purchase the home?"
        options={[
          "2024",
          "2023",
          "2022",
          "2021",
          "2020",
          "2019",
          "2018",
          "2017",
          "2016",
          "2015 or before",
        ]}
        width={175}
        progressReset={25}
        progressIncrease={40}
      />
      <MultiOptionsSelect
        name="primary_residence"
        previousController="year_purchased"
        visible={isQ4Visible}
        title="Will this be the home you live in most of the time?"
        options={["Yes", "No"]}
        width={100}
        progressReset={40}
        progressIncrease={60}
      />
      <MultiOptionsSelect
        name="home_sharing"
        previousController="primary_residence"
        visible={isQ5Visible}
        title="Will your participate in home sharing programs, home exchanges or short-term rentals?"
        options={["Yes", "No"]}
        width={100}
        progressReset={60}
        progressIncrease={80}
      />
      <MultiOptionsSelect
        name="smoke"
        forceScroll={true}
        previousController="home_sharing"
        visible={isQ6Visible}
        title="Does anyone living in the home smoke?"
        options={["Yes", "No"]}
        width={100}
        progressReset={80}
        progressIncrease={100}
      />
    </div>
  );
};

export default InitialInfo;
