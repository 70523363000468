import * as yup from "yup";

export const initialInfoSchema = yup.object().shape({
  own_or_rent: yup.number().required(),
  dwelling_type: yup.number().when("own_or_rent", {
    is: (v) => v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  year_purchased: yup.number().when("dwelling_type", {
    is: (v) => v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  primary_residence: yup.number().when("year_purchased", {
    is: (v) => v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  home_sharing: yup.number().when("primary_residence", {
    is: (v) => v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  smoke: yup.number().when("home_sharing", {
    is: (v) => v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const describeHomeSchema = yup.object().shape({
  year_built: yup.number().required(),
  year_roof_updated: yup.number().when(["year_purchased", "year_built"], {
    is: (yearPurchased, yearBuilt) =>
      yearPurchased &&
      yearBuilt &&
      2025 - (yearPurchased ?? 1) - yearBuilt + 1 < 2009,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  basement: yup.number().required(),
  basement_finished: yup.number().when("basement", {
    is: (basement) => basement === 1,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  num_stories: yup.number().required(),
  num_bathrooms: yup.number().required(),
  num_fireplaces: yup.number().required(),
  water_heater_replaced: yup.number().required(),
  construction_type: yup.number().required(),
  roof_type: yup.number().required(),
  water_damage: yup.number().required(),
});

export const profileSchema = yup.object().shape({
  active_policy: yup.number().required(),
  active_policy_year: yup.number().when("active_policy", {
    is: (active_policy) => active_policy === 1,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  loss_claim: yup.number().required(),
  credit_check: yup
    .number()
    .required()
    .test("is-1", "this must be true", (value) => value == 1),
});

export const coverageSchema = yup.object().shape({
  building: yup.number().when("building_custom", {
    is: (amount) => !amount,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  additional: yup.number().when("additional_custom", {
    is: (amount) => !amount,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  personal_properties: yup.number().when("personal_properties_custom", {
    is: (amount) => !amount,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(),
  }),
  premises: yup.number().required(),
  comprehensive: yup.number().required(),
  earthquake: yup.number().required(),
});
