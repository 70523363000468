import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, Flex, Form, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  navigateToNextPage,
  navigateToPreviousPage,
} from "../../store/homeApplication/homeApplicationSlice";
import { submitApplication } from "../../store/homeApplication/homeApplicationThunks";
import { useParams } from "react-router-dom";
import { currencyFormatter, getPremium } from "../../shared/CoverageSelect";

const HomeApplicationWrapper = ({ schema, children }) => {
  const { applicationId } = useParams();
  const data = useSelector((state) => state.homeApplication.data);
  const formMethods = useForm({
    mode: "all",
    defaultValues: data,
    values: data,
    shouldFocusError: false,
    resetOptions: {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: true,
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = formMethods;

  const dispatch = useDispatch();

  const step = useSelector((state) => state.homeApplication.step);

  const onFinish = (values) => {
    if (step === 4) {
      dispatch(submitApplication({ id: applicationId }));
    }
    dispatch(navigateToNextPage(values));
  };

  const totalPremium =
    getPremium("building", getValues().building, getValues().building_custom) +
    getPremium(
      "personal_properties",
      getValues().personal_properties,
      getValues().personal_properties_custom
    ) +
    getPremium(
      "additional",
      getValues().additional,
      getValues().additional_custom
    ) +
    getPremium("premises", getValues().premises, null);
  return (
    <div
      style={{
        background: "white",
      }}
    >
      <FormProvider {...formMethods}>
        <Form
          name="private_aviation_user"
          autoComplete="off"
          layout="vertical"
          labelWrap
          onFinish={() => {
            handleSubmit(onFinish, () => {})();
          }}
          size="small"
        >
          <div
            style={{
              background: "white",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            {children}
          </div>
          <div style={{ height: "430px" }}></div>
          <div
            className="button-bar"
            style={{
              background: "#f5f5f5",
            }}
          >
            {step === 4 && (
              <Flex
                justify="start"
                style={
                  {
                    // paddingTop: "12px",
                    // paddingBottom: "12px",
                    // background: "#ebe6d9",
                  }
                }
              >
                <Typography.Paragraph
                  style={{ marginTop: 6, paddingLeft: 12, marginBottom: 12 }}
                >
                  <Typography.Text style={{ fontSize: 16 }} strong>
                    Total premium:{" "}
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: 16 }} mark>
                    {currencyFormatter.format(totalPremium)}/month
                  </Typography.Text>
                </Typography.Paragraph>
              </Flex>
            )}
            {step === 4 && <Divider style={{ marginTop: 0 }} dashed />}
            <Flex
              justify="start"
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={!isValid}
                style={{
                  width: "100%",
                  maxWidth: "250px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
              >
                {step == 4 ? "Submit" : "Continue"}
                <ArrowRightOutlined />
              </Button>
              {step > 1 && (
                <Button
                  size="large"
                  style={{
                    width: "100%",
                    marginLeft: "12px",
                    marginRight: "12px",
                    maxWidth: "250px",
                  }}
                  onClick={() => {
                    dispatch(navigateToPreviousPage({ data: getValues() }));
                  }}
                >
                  <ArrowLeftOutlined />
                  Previous
                </Button>
              )}
            </Flex>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default HomeApplicationWrapper;
