import { Route, Routes } from "react-router-dom";
import HomeApplication from "../../applications/HomeApplication";
import HomeRedirect from "../HomeRedirect";
import ResultPage from "../ResultPage";
import PublicLayout from "../layouts/PublicLayout";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route
          path="/home-applications/:applicationId"
          element={<HomeApplication />}
        />
      </Route>
      <Route path="/" element={<HomeRedirect />} />
      <Route path="*" element={<ResultPage status={404} />} />
    </Routes>
  );
};

export default AppRoutes;
