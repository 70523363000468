import { Button, Col, Flex, Row, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { MultiOptionsSelect } from "../../../shared/MultiOptionsSelect";

const Profile = () => {
  const { watch } = useFormContext();

  const values = watch();

  const isQ4Visible = values?.active_policy === 1;
  const isQ5Visible =
    (isQ4Visible && values?.active_policy_year) || values?.active_policy === 2;
  const isQ6Visible = isQ5Visible && values?.loss_claim;

  return (
    <div>
      <div id="first_and_last">
        <Row>
          <Col span={24}>
            <Typography.Title
              level={4}
              style={{ marginTop: "12px", marginBottom: "12px" }}
            >
              What are your first and last name?
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col>
            <Button
              size="large"
              style={{
                whiteSpace: "normal",
                height: "auto",
                minHeight: "40px",
                width: "auto",
              }}
              type="primary"
              disabled={true}
            >
              <Flex justify="space-between">
                <span style={{ textAlign: "left" }}>{values.first_name}</span>
              </Flex>
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              style={{
                whiteSpace: "normal",
                height: "auto",
                minHeight: "40px",
                width: "auto",
              }}
              type="primary"
              disabled={true}
            >
              <Flex justify="space-between">
                <span style={{ textAlign: "left" }}>{values.last_name}</span>
              </Flex>
            </Button>
          </Col>
        </Row>
      </div>
      <MultiOptionsSelect
        forceScroll={true}
        previousController="first_and_last"
        name="active_policy"
        visible={true}
        title="Do you currently have an active home insurance policy?"
        options={["Yes", "No"]}
        width={100}
        progressReset={0}
        progressIncrease={25}
      />

      <MultiOptionsSelect
        name="active_policy_year"
        previousController={"active_policy"}
        visible={isQ4Visible}
        title="Since what year have you had an active policy?"
        options={[
          "2024",
          "2023",
          "2022",
          "2021",
          "2020",
          "2019",
          "2016-2018",
          "2013-2015",
          "2012 or before",
        ]}
        width={175}
        progressReset={25}
        progressIncrease={50}
      />
      <MultiOptionsSelect
        name="loss_claim"
        previousController={
          isQ4Visible ? "active_policy_year" : "active_policy"
        }
        visible={isQ5Visible}
        title="Have you suffered a loss, or are you aware of any imminent threats, that may result in a claim under this policy?"
        options={["Yes", "No"]}
        width={100}
        progressReset={50}
        progressIncrease={75}
      />
      <MultiOptionsSelect
        name="credit_check"
        previousController={"credit_check"}
        visible={isQ6Visible}
        title="To offer you an accurate quote, Homelink uses information that you provide along with claims and credit histories we retrieve. Would you like to proceed? By continuing with this quote, you authorize us to contact you regarding this quote by email, phone, text message, or other means. You can opt-out or update your preferences at any time."
        options={["Yes", "No"]}
        width={100}
        progressReset={75}
        progressIncrease={100}
        forceScroll={true}
      />
    </div>
  );
};

export default Profile;
