import { combineReducers } from "@reduxjs/toolkit";
import homeApplicationSlice from "./homeApplication/homeApplicationSlice";
import notificationsSlice from "./notifications/notificationsSlice";

function createRootReducer() {
  const appReducer = combineReducers({
    homeApplication: homeApplicationSlice,
    notifications: notificationsSlice,
  });

  const rootReducer = (state, action) => {
    if (action.type === "ERASE_REDUX_STORE") {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  return rootReducer;
}
export default createRootReducer;
