import { HomeOutlined, LoginOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Dropdown, Layout, Menu, Row } from "antd";
import { Outlet } from "react-router-dom";
import { mainTheme, outerLayersTheme } from "../../../shared/themes";
import LayoutFooter from "./LayoutFooter";
import MainHeader from "./MainHeader";
import NotificationsWrapper from "./NotificationsWrapper";

const getDropdownItems = [
  {
    key: "0",
    label: "Home",

    icon: <HomeOutlined />,
  },
  {
    key: "1",
    label: "Login",
    icon: <LoginOutlined />,
  },
];

const PublicLayout = ({ isDirectParent, children }) => {
  return (
    <div>
      <ConfigProvider theme={mainTheme}>
        <NotificationsWrapper>
          <Layout className="layout">
            <ConfigProvider theme={outerLayersTheme}>
              <MainHeader>
                <div>
                  <Row>
                    <Col xs={0} sm={24}>
                      <Menu
                        selectedKeys={null}
                        theme="dark"
                        mode="horizontal"
                        items={getDropdownItems}
                        style={{
                          flex: 1,
                          minWidth: 0,
                          justifyContent: "flex-end",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={0}>
                      <Row justify="end">
                        <Col style={{ marginRight: "16px" }}>
                          <Dropdown
                            menu={{ items: getDropdownItems }}
                            placement="bottom"
                          >
                            <Button type="primary">
                              <MenuOutlined />
                            </Button>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </MainHeader>
            </ConfigProvider>
            <Layout>
              <div>
                <div
                  style={{
                    background: "#f5f5f5",
                    flex: 1,
                  }}
                >
                  {isDirectParent === true ? children : <Outlet />}
                </div>
                <LayoutFooter />
              </div>
            </Layout>
          </Layout>
        </NotificationsWrapper>
      </ConfigProvider>
    </div>
  );
};

export default PublicLayout;
