import { useFormContext } from "react-hook-form";
import { MultiOptionsSelect } from "../../../shared/MultiOptionsSelect";
import { Button, Col, Collapse, InputNumber, Row, Typography } from "antd";
import { InputCurrency } from "../../../shared/InputCurrency";
import { CoverageSelect } from "../../../shared/CoverageSelect";
import { useEffect } from "react";

const Coverage = () => {
  const { watch } = useFormContext();

  const values = watch();

  const yearPurchased = 2025 - (values?.year_purchased ?? 1);
  const isQ2Visible =
    values?.year_built && yearPurchased - values?.year_built + 1 < 2009;
  const isQ3Visible =
    (isQ2Visible && values?.year_roof_updated) ||
    (values?.year_built && yearPurchased - values?.year_built + 1 > 2008);
  const isQ4Visible = isQ3Visible && values?.basement === 1;
  const isQ5Visible =
    (isQ4Visible && values?.basement_finished) ||
    (isQ3Visible && values?.basement === 2);
  const isQ6Visible = isQ5Visible && values?.num_stories;
  const isQ7Visible = isQ6Visible && values?.num_bathrooms;
  const isQ8Visible = isQ7Visible && values?.num_fireplaces;
  const isQ9Visible = isQ8Visible && values?.construction_type;
  const isQ10Visible = isQ9Visible && values?.roof_type;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        1. Choose your coverages
      </Typography.Title>

      <CoverageSelect
        options={["$1,000,000", "$2,000,000"]}
        title="Building"
        name="building"
        hasCustom
      />
      <CoverageSelect
        options={["$25,000"]}
        title="Personal Property Coverage on Premises"
        name="personal_properties"
        hasCustom
      />
      <CoverageSelect
        options={["Don't want", "$55,000"]}
        title="Additional Living Expenses Coverage"
        name="additional"
        hasCustom
      />
      <CoverageSelect
        options={["$500,000", "$1,000,000", "$2,000,000"]}
        title="Premises Liability Coverage"
        name="premises"
      />

      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        2. Choose your protection levels and deductibles
      </Typography.Title>

      <CoverageSelect
        options={["$500", "$1,000", "$2,500"]}
        title="Comprehensive Protection and Standard Deductible"
        name="comprehensive"
        hidePremium={true}
      />
      <CoverageSelect
        options={["Exclude protection", "$50,000", "$100,000"]}
        title="Earthquake Protection and Deductible"
        name="earthquake"
        hidePremium={true}
      />
    </div>
  );
};

export default Coverage;
