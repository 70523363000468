/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Alert,
  Col,
  ConfigProvider,
  Flex,
  Grid,
  Progress,
  Row,
  Skeleton,
  Steps,
  Typography,
  theme,
} from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearHomeApplication } from "../../store/homeApplication/homeApplicationSlice";
import { fetchApplication } from "../../store/homeApplication/homeApplicationThunks";
import HomeApplicationWrapper from "./HomeApplicationWrapper";
import {
  coverageSchema,
  describeHomeSchema,
  initialInfoSchema,
  profileSchema,
} from "./formSchema";
import Coverage from "./steps/Coverage";
import DescribeHome from "./steps/DescribeHome";
import InitialInfo from "./steps/InitialInfo";
import Profile from "./steps/Profile";
import { Submitted } from "./steps/Submitted";
const { useBreakpoint } = Grid;

const HomeApplication = () => {
  //
  const { applicationId } = useParams();

  //
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isSmall = !screens.md && !screens.lg && !screens.xl && !screens.xxl;

  //
  const fetchStatus = useSelector((state) => state.homeApplication.fetchStatus);
  const step = useSelector((state) => state.homeApplication.step);
  const progress = useSelector((state) => state.homeApplication.progress);
  const data = useSelector((state) => state.homeApplication.data);
  const isError = fetchStatus == "rejected";
  const isSuccess = fetchStatus == "fulfilled";
  const isUninitialized = !fetchStatus;
  const isLoading = fetchStatus == "pending";

  const quoteNumber = useSelector(
    (state) => state.homeApplication.data?.quote_number
  );

  //
  useEffect(() => {
    dispatch(fetchApplication({ id: applicationId }));
    return () => {
      dispatch(clearHomeApplication());
    };
  }, []);

  const items = useMemo(
    () => [
      {
        title: "Initial info",
      },
      {
        title: "Description",
        disabled: step < 2,
      },
      {
        title: "Profile",
        disabled: step < 3,
      },
      {
        title: "Coverage",
        disabled: step < 4,
      },
    ],
    [step]
  );

  return isError ||
    (isSuccess && data != null && (data.error || data.status == "404")) ? (
    <div
      style={{ height: "50px", paddingTop: 12 }}
      className="centered-body-background"
    >
      <Alert
        style={{ marginTop: 0, marginBottom: 0, paddingTop: 12 }}
        message={`Error: Something went wrong while loading this application!`}
        type="error"
        showIcon
        className="default-margin"
      />
    </div>
  ) : !isLoading &&
    !isUninitialized &&
    (step === 5 || data?.status?.toLowerCase() == "quoted") ? (
    <Submitted />
  ) : (
    <Skeleton
      loading={isLoading || isUninitialized}
      active
      className="home-application-skeleton"
    >
      <div>
        <div className="top-bar">
          <Flex vertical>
            <Row
              justify="space-between"
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
            >
              <Col flex="200px">
                <Typography.Title
                  level={4}
                  color="#ffffff"
                  style={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    // minWidth: 200,
                    color: "black",
                  }}
                >
                  Your quote #{quoteNumber}
                </Typography.Title>
              </Col>
              <Col flex="auto">
                <Flex justify="end">
                  {isSmall && (
                    <Typography.Title
                      level={5}
                      color="#ffffff"
                      style={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        color: "black",
                      }}
                    >
                      Step {step} of 4
                    </Typography.Title>
                  )}
                  {!isSmall && (
                    <Steps
                      style={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        marginRight: 32,
                        maxWidth: 600,
                      }}
                      size="small"
                      current={step - 1}
                      items={items}
                    />
                  )}
                </Flex>
              </Col>
            </Row>
            <Progress
              style={{ margin: "0", padding: "0", background: "white" }}
              percent={progress?.[step - 1] ?? 50}
              size="small"
              strokeLinecap="square"
              showInfo={false}
            />
          </Flex>
        </div>
        {step == 1 && (
          <HomeApplicationWrapper schema={initialInfoSchema}>
            <InitialInfo />
          </HomeApplicationWrapper>
        )}
        {step === 2 && (
          <HomeApplicationWrapper schema={describeHomeSchema}>
            <DescribeHome />
          </HomeApplicationWrapper>
        )}
        {step === 3 && (
          <HomeApplicationWrapper schema={profileSchema}>
            <Profile />
          </HomeApplicationWrapper>
        )}
        {step === 4 && (
          <HomeApplicationWrapper schema={coverageSchema}>
            <Coverage />
          </HomeApplicationWrapper>
        )}
      </div>
    </Skeleton>
  );
};

export default HomeApplication;
