import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Alert, Flex } from "antd";
import { useEffect } from "react";

export const Submitted = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div style={{ height: "50px" }} className="centered-body-background">
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <Alert
          message="Submitted"
          showIcon
          description={
            <p>
              Thank you for submitting your home insurance application. Our team
              has received it, and we will be in touch shortly.
              <br />
              <br />
              If you have any inquiries about your application, please feel free
              to contact us:
              <ul>
                <li style={{ listStyleType: "none" }}>
                  <MailOutlined />
                  &nbsp;&nbsp;
                  <a href="mailto:info@homelink.com">info@homelink.com</a>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <PhoneOutlined />
                  &nbsp;&nbsp;
                  <a href="tel:8881234567">(888) 123-4567</a>
                </li>
              </ul>
            </p>
          }
          type="success"
          style={{
            minWidth: 300,
            maxWidth: 800,
            marginRight: "24px",
            marginLeft: "24px",
          }}
          action={null}
        />
      </Flex>
    </div>
  );
};
